$padding-large-vertical: 14px;

.container-fluid {
  margin-top: $padding-large-vertical;
}

// revert navbar margin on landing page
.landing-page {
  margin-top: -48px;
}

// fix this file structure if you can

// use bootstrap 4 classes
.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.align-self-start {
  align-self: flex-start;
}

// increase height list header sorting
.list-header-sorting {
  padding-top: 16px;
  padding-bottom: 16px;
}

// shame
.profile-card {
  max-width: 1240px;
}

.app-loading {
  font-size: var(--loading-spinner-size);
  align-items: center;
  justify-content: center;
}

.loading-ring {
  animation: var(--loading-spinner-animation);
}

.all-app-modal {
  position: absolute;
  width: 452px;
  margin-top: 50px;
  margin-left: 0;

  .modal-content {
    border-radius: 0;
  }
}
